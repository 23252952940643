import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { FooterModule } from 'app/shared/components/footer/footer.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {},
  },
];

const COMPONENTS = [HomeComponent];

@NgModule({
  imports: [SharedModule, FooterModule, RouterModule.forRoot(routes)],
  declarations: [...COMPONENTS],
  exports: [RouterModule, ...COMPONENTS],
})
export class HomeModule {}
