<div class="coupons">
  <div class="d-flex mb-35">
    <img
      mat-card-lg-image
      [src]="storeCoupons.img"
      alt="logotype"
      style="border-radius: 8px"
    />
    <mat-card-content>
      <mat-card-title>{{
        "coupons." + storeCoupons.title | translate
      }}</mat-card-title>
      <mat-card-subtitle>{{ storeCoupons.subtitle }}</mat-card-subtitle>
    </mat-card-content>
  </div>
  <div class="col-12">
    <div class="row gap-5">
      <div *ngFor="let coupon of storeCoupons.coupons" class="coupons-list">
        <mat-card class="mat-elevation-z">
          <mat-card-header>
            <div class="discount-percent" mat-card-avatar>
              {{ coupon.discount }}
            </div>
            <mat-card-title>{{ coupon.title }}</mat-card-title>
            <mat-card-subtitle>{{ coupon.subtitle }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <mat-form-field class="w-100">
              <input [(ngModel)]="coupon.code" readonly matInput type="text" />
              <button
                [cdkCopyToClipboard]="coupon.code"
                aria-label="Copy"
                mat-icon-button
                matSuffix
              >
                <mat-icon svgIcon="mdc:copy"></mat-icon>
              </button>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
