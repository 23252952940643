import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
  imports: [SharedModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
