<!--<mat-nav-list class="breadcrumb">
  <a mat-list-item *ngFor="let breadcrumb of breadcrumbs" [routerLink]="[breadcrumb.url]">
    &lt;!&ndash;<mat-icon class="color-light-primary" svgIcon="mdc:menu"></mat-icon>&ndash;&gt;
    <span class="menu-text">{{
        'breadcrumb.' + breadcrumb.label | translate
      }}</span>
  </a>
</mat-nav-list>-->

<ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs">
    <a [routerLink]="breadcrumb.url" routerLinkActive="router-link-active"
      >{{ "breadcrumb." + breadcrumb.label | translate }}
    </a>
  </li>
</ol>
