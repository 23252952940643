import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective {
  constructor(private el: ElementRef) {}

  public ngAfterViewInit() {
    this.el.nativeElement.focus();
  }
}
