<div class="layout-header">
  <h1 class="layout-header__title mt-0 mb-0 mr-25 ml-25">
    {{ "home.Title" | translate }}
  </h1>
</div>

<div class="m-25">
  <div class="split-screen">
    <img
      alt="logotype"
      class="home-logo"
      src="assets/images/sozdai-ravnovesie.jpg"
    />
    <div class="bg"></div>
  </div>
  <h2>Преимущества посещения сайта:</h2>
  <div class="advantage">
    <ul>
      <li>
        <strong>Актуальные предложения -</strong> Все промокоды регулярно
        обновляются, что позволяет избегать разочарований при попытке
        использовать уже неработающие скидки.
      </li>
      <li>
        <strong>Большое количество партнеров -</strong> На сайте представлены
        промокоды от множества интернет-магазинов, сервисов и компаний,
        охватывающих разные категории товаров — от электроники до услуг красоты.
      </li>
      <li>
        <strong>Экономия времени -</strong> Вам не нужно искать скидки по разным
        сайтам. Все актуальные предложения собраны в одном месте, что экономит
        ваше время и силы.
      </li>
      <li>
        <strong>Отсутствие рекламы -</strong> Реклама может быть прибыльным
        источником дохода для сайтов, но она оказывает негативное влияние на
        пользовательский опыт и доверие к контенту.
      </li>
    </ul>
  </div>
  <div class="manual">
    <h2>Вот как можно воспользоваться сайтом:</h2>
    <ul>
      <li><span>Зайдите на главную страницу сайта.</span></li>
      <li>
        <span
          >Введите в поисковую строку название магазина или услуги, для которой
          вам нужен промокод.</span
        >
      </li>
      <li><span>Найдите подходящий промокод и скопируйте его.</span></li>
      <li>
        <span
          >Перейдите на сайт магазина, введите промокод при оформлении заказа, и
          ваша скидка будет активирована.</span
        >
      </li>
    </ul>
  </div>
  <!--<p>
    Наша команда экспертов создала удобный онлайн-ресурс, благодаря которому
    пользователи ежедневно экономят сотни тысяч рублей на покупках. Здесь
    систематически публикуются тысячи актуальных купонов в десятках категорий от
    популярных интернет-магазинов России и зарубежья. Мы постоянно анализируем
    тренды, выявляем самые востребованные скидки, чтобы предложить больше
    популярных промокодов. Каждая страница с промокодами проверяется и
    контролируется.
  </p>-->
</div>
<app-footer></app-footer>
