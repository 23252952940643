import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

import { LayoutComponent } from './layout.component';
import { BreadcrumbModule } from 'app/shared/components/breadcrumb/breadcrumb.module';
import { AutoFocusDirective } from 'app/directives/auto-focus.directive';

const COMPONENTS = [LayoutComponent];

@NgModule({
  imports: [SharedModule, BreadcrumbModule, AutoFocusDirective],
  declarations: COMPONENTS,
  exports: [COMPONENTS],
})
export class LayoutModule {}
