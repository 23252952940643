<ng-container *ngIf="noLayout; else layout2">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #layout2>
  <ng-container *ngIf="resetPasswordLayout; else layout">
    <mat-toolbar>
      <div class="d-flex align-items-center">
        <button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async"
          aria-label="Toggle sidenav"
          mat-icon-button
          type="button"
        >
          <mat-icon
            aria-label="Side nav toggle icon"
            svgIcon="mdc:menu"
          ></mat-icon>
        </button>
        <span class="cursor-pointer" routerLink="settings"
          ><img src="/assets/images/logo-connectivity-white.png" width="250"
        /></span>
      </div>
      <span class="spacer"></span>
      <span class="help-wrapper">
        <!--<a
          *ngIf="statusIcon"
          mat-icon-button
          routerLink="settings/general"
          target="_blank"
        >
          <img
            height="24"
            src="{{ 'data:image/png;base64,' + statusIcon }}"
            width="24"
          />
        </a>-->
        <a [href]="supportHref" mat-icon-button target="_blank">
          <mat-icon
            class="color-light-primary"
            svgIcon="mdc:unknown"
          ></mat-icon>
        </a>
      </span>
    </mat-toolbar>

    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav
        #drawer
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false"
        class="sidenav"
        fixedInViewport="false"
      >
        <mat-toolbar>
          <mat-icon svgIcon="mdc:settings"></mat-icon>
          <span class="menu-heading">{{ "layout.Settings" | translate }}</span>
        </mat-toolbar>
        <mat-nav-list>
          <a mat-list-item routerLink="reset-password">
            <mat-icon svgIcon="mdc:lock"></mat-icon>
            <span class="menu-text" routerLinkActive="active">{{
              "layout.ChangePassword" | translate
            }}</span>
          </a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>

  <ng-template #layout>
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav
        #drawer
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [disableClose]="(isHandset$ | async) === false"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false"
        class="sidenav"
        fixedInViewport="false"
      >
        <mat-toolbar class="justify-content-between column-gap-5">
          <button
            (click)="drawer.toggle()"
            *ngIf="isHandset$ | async"
            aria-label="Toggle sidenav"
            mat-icon-button
            type="button"
          >
            <mat-icon
              aria-label="Side nav toggle icon"
              class="color-light-primary"
              svgIcon="mdc:close"
            ></mat-icon>
          </button>
          <span
            *ngIf="!(isHandset$ | async)"
            class="cursor-pointer"
            routerLink="/"
            >Промокодная Бухта</span
          >
          <span class="spacer"></span>
          <button (click)="showSearch($event)" mat-button type="button">
            <mat-icon
              class="color-light-primary d-inline-flex"
              svgIcon="mdc:search"
            ></mat-icon>
            <span>Search</span>
          </button>
          <button (click)="showMenu($event)" color="ghost-secondary" mat-button>
            Menu
          </button>
        </mat-toolbar>
        <div *ngIf="isShowSearch" class="d-block mr-20 ml-20 mt-70">
          <mat-form-field class="w-100">
            <input
              matInput
              appAutoFocus
              [placeholder]="'layout.InputSearch' | translate"
              type="text"
              style="caret-color: #fff"
              [ngModel]="searchValue"
              (ngModelChange)="onSearchChanged($event)"
            />
            @if (searchValue) {
              <button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="searchValue = ''"
              >
                <mat-icon svgIcon="mdc:close"></mat-icon>
              </button>
            }
            <mat-divider class="search-divider" role="separator"></mat-divider>
          </mat-form-field>
        </div>
        <mat-nav-list *ngIf="isShowMenu || (isShowSearch && searchValue)">
          <div class="d-block m-20">
            <div class="row">
              <div class="col-12">
                <div
                  [@listAnimation]="cashbackStores.length"
                  class="row row-gap-4"
                >
                  <div
                    *ngFor="let store of cashbackStores"
                    class="col-md-4 cursor-pointer"
                    [routerLink]="store.path"
                  >
                    <mat-card
                      [style.pointer-events]="
                        (isHandset$ | async) ? 'auto' : 'none'
                      "
                      (click)="drawer.close()"
                      appearance="outlined"
                    >
                      <img [src]="store.src" mat-card-image />
                      <mat-card-content>{{
                        "layout." + store.label | translate
                      }}</mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-nav-list>
        <div *ngIf="isShowSearch || isShowMenu" class="overlay-container"></div>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-toolbar>
          <div class="d-flex align-items-center">
            <button
              (click)="drawer.toggle()"
              *ngIf="isHandset$ | async"
              aria-label="Toggle sidenav"
              mat-icon-button
              type="button"
            >
              <mat-icon
                aria-label="Side nav toggle icon"
                class="color-light-primary"
                svgIcon="mdc:settings"
              ></mat-icon>
            </button>
            <app-breadcrumb></app-breadcrumb>
          </div>
          <span class="spacer"></span>
          <span class="help-wrapper d-flex align-items-center">
            <mat-form-field class="small" subscriptSizing="dynamic">
              <mat-select
                (valueChange)="onLanguageChange($event)"
                [value]="currentLang"
                disableOptionCentering="true"
                hideSingleSelectionIndicator="true"
                panelClass="p-0"
              >
                <mat-option value="ru">ru</mat-option>
                <mat-option value="en">en</mat-option>
              </mat-select>
            </mat-form-field>
            <a [href]="supportHref" mat-icon-button target="_blank">
              <mat-icon
                class="color-light-primary"
                svgIcon="mdc:unknown"
              ></mat-icon>
            </a>
            <button
              (click)="drawer.toggle()"
              *ngIf="!(isHandset$ | async)"
              aria-label="Toggle sidenav"
              mat-icon-button
              type="button"
            >
              <mat-icon
                class="color-light-primary"
                svgIcon="mdc:fullscreen"
              ></mat-icon>
            </button>
          </span>
        </mat-toolbar>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-template>
</ng-template>
