/**
 * MISC CUSTOM SVG ICONS
 * When importing SVG asset files here
 * please prep the SVG file by removing
 * any styling form the <style> tag or inline
 * as it conflicts with our application CSS.
 */
export const mdcSvgIcons = {
  'file-system': 'assets/svg-icon/file-system.svg',
  search: 'assets/svg-icon/search.svg',
  checkmark: 'assets/svg-icon/checkmark.svg',
  pallet: 'assets/svg-icon/pallet.svg',
  'arrow-down': 'assets/svg-icon/arrow-down.svg',
  system: 'assets/svg-icon/system.svg',
  'arrow-right-filled': 'assets/svg-icon/arrow-right-filled.svg',
  list: 'assets/svg-icon/list.svg',
  'measuring-unit': 'assets/svg-icon/measuring-unit.svg',
  'robot-transfer': 'assets/svg-icon/robot-transfer.svg',
  'order-move-up': 'assets/svg-icon/order-move-up.svg',
  unit: 'assets/svg-icon/unit.svg',
  'pallet-priority': 'assets/svg-icon/pallet-priority.svg',
  'pallet-clamp': 'assets/svg-icon/pallet-clamp.svg',
  reset: 'assets/svg-icon/reset.svg',
  'magnetic-clamp': 'assets/svg-icon/magnetic-clamp.svg',
  backlog: 'assets/svg-icon/backlog.svg',
  distance: 'assets/svg-icon/distance.svg',
  confirmation: 'assets/svg-icon/confirmation.svg',
  'acknowledgement-quit': 'assets/svg-icon/acknowledgement-quit.svg',
  user: 'assets/svg-icon/user.svg',
  'pallet-queue': 'assets/svg-icon/pallet-queue.svg',
  highlight: 'assets/svg-icon/highlight.svg',
  'nc-backup-history': 'assets/svg-icon/nc-backup-history.svg',
  fullscreen: 'assets/svg-icon/fullscreen.svg',
  'removable-drive': 'assets/svg-icon/removable-drive.svg',
  'stop-2': 'assets/svg-icon/stop-2.svg',
  'order-list': 'assets/svg-icon/order-list.svg',
  'step-next': 'assets/svg-icon/step-next.svg',
  disconnected: 'assets/svg-icon/disconnected.svg',
  workpiece: 'assets/svg-icon/workpiece.svg',
  'job-detail': 'assets/svg-icon/job-detail.svg',
  'pallet-empty': 'assets/svg-icon/pallet-empty.svg',
  'setup-station': 'assets/svg-icon/setup-station.svg',
  'change-view': 'assets/svg-icon/change-view.svg',
  'invalid-character': 'assets/svg-icon/invalid-character.svg',
  'order-add': 'assets/svg-icon/order-add.svg',
  drawing: 'assets/svg-icon/drawing.svg',
  'nc-backup': 'assets/svg-icon/nc-backup.svg',
  logout: 'assets/svg-icon/logout.svg',
  'start-2': 'assets/svg-icon/start-2.svg',
  'pallet-search': 'assets/svg-icon/pallet-search.svg',
  'robot-new': 'assets/svg-icon/robot-new.svg',
  'tool-changer-info': 'assets/svg-icon/tool-changer-info.svg',
  'pallet-material-new': 'assets/svg-icon/pallet-material-new.svg',
  unknown: 'assets/svg-icon/unknown.svg',
  'passed-time': 'assets/svg-icon/passed-time.svg',
  preplan: 'assets/svg-icon/preplan.svg',
  'pallet-material-done': 'assets/svg-icon/pallet-material-done.svg',
  'automatic-door-open': 'assets/svg-icon/automatic-door-open.svg',
  drag: 'assets/svg-icon/drag.svg',
  'resources-view': 'assets/svg-icon/resources-view.svg',
  'warning-history': 'assets/svg-icon/warning-history.svg',
  'arrow-jump-down': 'assets/svg-icon/arrow-jump-down.svg',
  'arrow-up-right-filled': 'assets/svg-icon/arrow-up-right-filled.svg',
  'order-move-down': 'assets/svg-icon/order-move-down.svg',
  'schedule-cell-reset': 'assets/svg-icon/schedule-cell-reset.svg',
  'automatic-door-reset': 'assets/svg-icon/automatic-door-reset.svg',
  'keyboard-down': 'assets/svg-icon/keyboard-down.svg',
  'more-h': 'assets/svg-icon/more-h.svg',
  'bar-chart': 'assets/svg-icon/bar-chart.svg',
  'tool-magazine-left': 'assets/svg-icon/tool-magazine-left.svg',
  lock: 'assets/svg-icon/lock.svg',
  workpieces: 'assets/svg-icon/workpieces.svg',
  diagnosis: 'assets/svg-icon/diagnosis.svg',
  connected: 'assets/svg-icon/connected.svg',
  'fixture-group-add': 'assets/svg-icon/fixture-group-add.svg',
  'tool-changer': 'assets/svg-icon/tool-changer.svg',
  order: 'assets/svg-icon/order.svg',
  'clamping-point-2': 'assets/svg-icon/clamping-point-2.svg',
  'arrow-right': 'assets/svg-icon/arrow-right.svg',
  'robot-running': 'assets/svg-icon/robot-running.svg',
  'arrow-up-left-filled': 'assets/svg-icon/arrow-up-left-filled.svg',
  settings: 'assets/svg-icon/settings.svg',
  compress: 'assets/svg-icon/compress.svg',
  'in-progress': 'assets/svg-icon/in-progress.svg',
  download: 'assets/svg-icon/download.svg',
  expand: 'assets/svg-icon/expand.svg',
  time: 'assets/svg-icon/time.svg',
  'status-monitor': 'assets/svg-icon/status-monitor.svg',
  gallery: 'assets/svg-icon/gallery.svg',
  'search-filtered': 'assets/svg-icon/search-filtered.svg',
  'clamping-point-1': 'assets/svg-icon/clamping-point-1.svg',
  'order-ungroup': 'assets/svg-icon/order-ungroup.svg',
  'connection-cloud-lte': 'assets/svg-icon/connection-cloud-lte.svg',
  login: 'assets/svg-icon/login.svg',
  collapse: 'assets/svg-icon/collapse.svg',
  workpiece_inout: 'assets/svg-icon/workpiece_inout.svg',
  'tool-report': 'assets/svg-icon/tool-report.svg',
  'zoom-to-fit': 'assets/svg-icon/zoom-to-fit.svg',
  'report-screen': 'assets/svg-icon/report-screen.svg',
  'arrow-down-filled': 'assets/svg-icon/arrow-down-filled.svg',
  update: 'assets/svg-icon/update.svg',
  stop: 'assets/svg-icon/stop.svg',
  machine: 'assets/svg-icon/machine.svg',
  tool: 'assets/svg-icon/tool.svg',
  equip: 'assets/svg-icon/equip.svg',
  'pallet-material-mixed': 'assets/svg-icon/pallet-material-mixed.svg',
  plus: 'assets/svg-icon/plus.svg',
  document: 'assets/svg-icon/document.svg',
  'order-load': 'assets/svg-icon/order-load.svg',
  'pallet-transfer': 'assets/svg-icon/pallet-transfer.svg',
  warning: 'assets/svg-icon/warning.svg',
  'order-group': 'assets/svg-icon/order-group.svg',
  'reports-view': 'assets/svg-icon/reports-view.svg',
  info: 'assets/svg-icon/info.svg',
  'pallet-new': 'assets/svg-icon/pallet-new.svg',
  replan: 'assets/svg-icon/replan.svg',
  close: 'assets/svg-icon/close.svg',
  active: 'assets/svg-icon/active.svg',
  'manual-mode': 'assets/svg-icon/manual-mode.svg',
  axis: 'assets/svg-icon/axis.svg',
  'more-v': 'assets/svg-icon/more-v.svg',
  'instruction-file': 'assets/svg-icon/instruction-file.svg',
  copy: 'assets/svg-icon/copy.svg',
  'zoom-in': 'assets/svg-icon/zoom-in.svg',
  'service-support-message': 'assets/svg-icon/service-support-message.svg',
  paused: 'assets/svg-icon/paused.svg',
  'order-settings': 'assets/svg-icon/order-settings.svg',
  process: 'assets/svg-icon/process.svg',
  'arrow-up-filled': 'assets/svg-icon/arrow-up-filled.svg',
  refresh: 'assets/svg-icon/refresh.svg',
  hide: 'assets/svg-icon/hide.svg',
  'spindle-vertical': 'assets/svg-icon/spindle-vertical.svg',
  image: 'assets/svg-icon/image.svg',
  save: 'assets/svg-icon/save.svg',
  planning: 'assets/svg-icon/planning.svg',
  'arrow-jump-up': 'assets/svg-icon/arrow-jump-up.svg',
  'pallet-changer': 'assets/svg-icon/pallet-changer.svg',
  start: 'assets/svg-icon/start.svg',
  'remaining-time': 'assets/svg-icon/remaining-time.svg',
  message: 'assets/svg-icon/message.svg',
  'access-guard': 'assets/svg-icon/access-guard.svg',
  'zoom-out': 'assets/svg-icon/zoom-out.svg',
  snippet: 'assets/svg-icon/snippet.svg',
  notification: 'assets/svg-icon/notification.svg',
  'tool-magazine-minus': 'assets/svg-icon/tool-magazine-minus.svg',
  'arrow-down-right-filled': 'assets/svg-icon/arrow-down-right-filled.svg',
  'connection-cloud-wifi': 'assets/svg-icon/connection-cloud-wifi.svg',
  'automatic-control': 'assets/svg-icon/automatic-control.svg',
  'app-manager': 'assets/svg-icon/app-manager.svg',
  send: 'assets/svg-icon/send.svg',
  'reference-point': 'assets/svg-icon/reference-point.svg',
  'cloud-drive': 'assets/svg-icon/cloud-drive.svg',
  'keyboard-left': 'assets/svg-icon/keyboard-left.svg',
  part: 'assets/svg-icon/part.svg',
  redo: 'assets/svg-icon/redo.svg',
  cycle: 'assets/svg-icon/cycle.svg',
  'cycle-start': 'assets/svg-icon/cycle-start.svg',
  'cycle-stop': 'assets/svg-icon/cycle-stop.svg',
  reschedule: 'assets/svg-icon/reschedule.svg',
  'connection-cloud-network': 'assets/svg-icon/connection-cloud-network.svg',
  'tool-lifetime': 'assets/svg-icon/tool-lifetime.svg',
  'automatic-mode': 'assets/svg-icon/automatic-mode.svg',
  jobviewer: 'assets/svg-icon/jobviewer.svg',
  'job-register': 'assets/svg-icon/job-register.svg',
  'order-details': 'assets/svg-icon/order-details.svg',
  network: 'assets/svg-icon/network.svg',
  job: 'assets/svg-icon/job.svg',
  'ipc-response': 'assets/svg-icon/ipc-response.svg',
  template: 'assets/svg-icon/template.svg',
  smartkey: 'assets/svg-icon/smartkey.svg',
  'automatic-door-close': 'assets/svg-icon/automatic-door-close.svg',
  edit: 'assets/svg-icon/edit.svg',
  'chip-screw': 'assets/svg-icon/chip-screw.svg',
  capture: 'assets/svg-icon/capture.svg',
  performance: 'assets/svg-icon/performance.svg',
  unlock: 'assets/svg-icon/unlock.svg',
  'service-support': 'assets/svg-icon/service-support.svg',
  'chip-conveyor': 'assets/svg-icon/chip-conveyor.svg',
  'setup-station-door': 'assets/svg-icon/setup-station-door.svg',
  'pallet-material-empty': 'assets/svg-icon/pallet-material-empty.svg',
  'account-key': 'assets/svg-icon/account-key.svg',
  'spindle-vertical-turn-both':
    'assets/svg-icon/spindle-vertical-turn-both.svg',
  'arrow-down-left-filled': 'assets/svg-icon/arrow-down-left-filled.svg',
  'power-on-off': 'assets/svg-icon/power-on-off.svg',
  'job-add': 'assets/svg-icon/job-add.svg',
  'tool-magazine-plus': 'assets/svg-icon/tool-magazine-plus.svg',
  forbidden: 'assets/svg-icon/forbidden.svg',
  'workpiece-queue': 'assets/svg-icon/workpiece-queue.svg',
  delete: 'assets/svg-icon/delete.svg',
  'arrow-left-filled': 'assets/svg-icon/arrow-left-filled.svg',
  success: 'assets/svg-icon/success.svg',
  'tool-changer-back': 'assets/svg-icon/tool-changer-back.svg',
  'pallet-material-raw': 'assets/svg-icon/pallet-material-raw.svg',
  'spindle-vertical-turn-right':
    'assets/svg-icon/spindle-vertical-turn-right.svg',
  'tool-changer-setup': 'assets/svg-icon/tool-changer-setup.svg',
  'monitor-view': 'assets/svg-icon/monitor-view.svg',
  'nc-code': 'assets/svg-icon/nc-code.svg',
  'teach-mode': 'assets/svg-icon/teach-mode.svg',
  'keyboard-right': 'assets/svg-icon/keyboard-right.svg',
  'pallet-unclamp': 'assets/svg-icon/pallet-unclamp.svg',
  'nc-folder': 'assets/svg-icon/nc-folder.svg',
  'arrow-up': 'assets/svg-icon/arrow-up.svg',
  schedule: 'assets/svg-icon/schedule.svg',
  view: 'assets/svg-icon/view.svg',
  undo: 'assets/svg-icon/undo.svg',
  video: 'assets/svg-icon/video.svg',
  attach: 'assets/svg-icon/attach.svg',
  filter: 'assets/svg-icon/filter.svg',
  comment: 'assets/svg-icon/comment.svg',
  calendar: 'assets/svg-icon/calendar.svg',
  'arrow-left': 'assets/svg-icon/arrow-left.svg',
  'setup-station-confirm': 'assets/svg-icon/setup-station-confirm.svg',
  manual: 'assets/svg-icon/manual.svg',
  'pallet-setup': 'assets/svg-icon/pallet-setup.svg',
  'expand-2': 'assets/svg-icon/expand-2.svg',
  cloud: 'assets/svg-icon/cloud.svg',
  error: 'assets/svg-icon/error.svg',
  unmanned: 'assets/svg-icon/unmanned.svg',
  upload: 'assets/svg-icon/upload.svg',
  'tool-magazine-right': 'assets/svg-icon/tool-magazine-right.svg',
  asterisk: 'assets/svg-icon/asterisk.svg',
  'spindle-horizontal': 'assets/svg-icon/spindle-horizontal.svg',
  'spindle-vertical-turn-left':
    'assets/svg-icon/spindle-vertical-turn-left.svg',
  'pallet-queue-transfer': 'assets/svg-icon/pallet-queue-transfer.svg',
  'workpieces-stocker': 'assets/svg-icon/workpieces-stocker.svg',
  'arrow-jump-left': 'assets/svg-icon/arrow-jump-left.svg',
  menu: 'assets/svg-icon/menu.svg',
  'job-group-add': 'assets/svg-icon/job-group-add.svg',
  'power-on-off-auto': 'assets/svg-icon/power-on-off-auto.svg',
  'keyboard-up': 'assets/svg-icon/keyboard-up.svg',
  'magnetic-unclamp': 'assets/svg-icon/magnetic-unclamp.svg',
  'multicell-scheduler-app': 'assets/svg-icon/multicell-scheduler-app.svg',
  'workpiece-queue-transfer': 'assets/svg-icon/workpiece-queue-transfer.svg',
  'data-points': 'assets/svg-icon/data-points.svg',
  history: 'assets/svg-icon/history.svg',
  'system-info': 'assets/svg-icon/system-info.svg',
  'tulip-player': 'assets/svg-icon/tulip-player.svg',
  alarm: 'assets/svg-icon/alarm.svg',
  minus: 'assets/svg-icon/minus.svg',
  print: 'assets/svg-icon/print.svg',
  light: 'assets/svg-icon/light.svg',
  'manual-operation-list': 'assets/svg-icon/manual-operation-list.svg',
  'tool-magazine': 'assets/svg-icon/tool-magazine.svg',
  'arrow-jump-right': 'assets/svg-icon/arrow-jump-right.svg',
  placeholder: 'assets/svg-icon/placeholder.svg',
};
