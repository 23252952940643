<main class="login-layout">
  <div
    class="split-screen d-flex align-items-start justify-content-center flex-column"
  >
    <img class="brand" src="/assets/images/logo-login.png" alt="logotype" />
    <img
      class="connectivity"
      src="/assets/images/logo-connectivity.png"
      alt="logotype"
    />
  </div>
  <div class="login-screen">
    <div class="login-logo" style="display: flex; width: 100%">
      <div
        style="
          display: flex;
          flex-direction: column;
          transform: scale(2);
          margin: 0 auto;
        "
      >
        <p style="margin: 0 auto">
          <span style="font-weight: 800">IoT</span
          ><span style="font-style: italic">connector</span>
        </p>
        <p style="margin: 0 0 0 auto">flex</p>
      </div>
    </div>
    <div class="login-form">
      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="LoginPageMode.Login">
          <form #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
            <mat-form-field>
              <mat-label>{{ "auth-login.Username" | translate }}</mat-label>
              <input
                matInput
                type="text"
                name="userName"
                [(ngModel)]="loginRequest.userName"
                required
              />
            </mat-form-field>

            <div class="password-block">
              <span class="forgot-password">
                <a href="#" (click)="openForgotPassword()">{{
                  "auth-login.ForgotPassword" | translate
                }}</a>
              </span>
              <mat-form-field>
                <mat-label class="d-flex">
                  <span>{{ "auth-login.Password" | translate }}</span>
                  <span>
                    <app-doc-btn
                      link="/help{{
                        'common.LanguageDocumentationPath' | translate
                      }}/docs/#login"
                      tooltip="{{
                        'auth-login.WhereToFindPassword' | translate
                      }}"
                    ></app-doc-btn>
                  </span>
                </mat-label>

                <input
                  matInput
                  type="password"
                  name="password"
                  [(ngModel)]="loginRequest.password"
                  required
                />
              </mat-form-field>
            </div>

            <div class="text-center">
              <button
                mat-button
                color="primary"
                type="submit"
                [disabled]="form.invalid"
              >
                {{ "auth-login.SignIn" | translate }}
              </button>
            </div>
          </form>
        </ng-container>
      </ng-container>
    </div>
  </div>
</main>
