import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { Status, Store, StoreFactory } from 'app/shared/state';

import CASHBACK_STORES from 'app/services/constants/cashbackStores';
import { Stores } from 'app/models';

export class layoutState {
  status!: Status;
  cashbackStores!: Stores[];
}

@Injectable()
export class LayoutService {
  private _store: Store<layoutState>;

  constructor(
    storeFactory: StoreFactory<layoutState>,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    this._store = storeFactory.startFrom(this._emptyState());
  }

  getCashbackStores() {
    return CASHBACK_STORES;
  }

  private _emptyState() {
    return <layoutState>{
      status: Status.NotInitialized,
    };
  }
}
