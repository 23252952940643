import { NgModule } from '@angular/core';
import {
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
  withSessionStorage,
} from 'ngx-webstorage';

import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { HttpMockupService } from './http-mockup.service';
import { HttpService } from './http.service';

import { PromptService } from './prompt.service';

@NgModule({
  providers: [
    provideNgxWebstorage(
      withNgxWebstorageConfig({ prefix: 'mdcl', separator: '.' }),
      withLocalStorage(),
      withSessionStorage(),
    ),
    AuthService,
    LocalStorageService,
    HttpMockupService,
    HttpService,
    PromptService,
    /*ConfigurationAgentHttpService,
    ConfigurationAgentHttpMockupService*/
  ],
})
export class SharedServicesModule {}
