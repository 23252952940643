import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { first, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LocalStorageService } from 'app/shared';
import { TranslateService } from '@ngx-translate/core';
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import CASHBACK_STORES from 'app/services/constants/cashbackStores';

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger('50ms', animate('500ms ease-out', style({ opacity: 1 }))),
      ],
      { optional: true },
    ),
    query(':leave', animate('200ms', style({ opacity: 0 })), {
      optional: true,
    }),
  ]),
]);

@Component({
  selector: 'app-layer',
  animations: [listAnimation],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );
  originalCashbackStores = [];
  cashbackStores = [];
  isShowSearch = false;
  isShowMenu = false;
  noLayout = false;
  resetPasswordLayout = false;
  searchValue = '';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.originalCashbackStores = CASHBACK_STORES;
  }

  get supportHref() {
    return `${window.location.protocol}//${window.location.hostname}/help${this.translate.instant('common.LanguageDocumentationPath')}/docs/`;
  }

  get currentLang() {
    return this.translate.currentLang;
  }

  onLanguageChange(value: string) {
    this.translate.use(value);
    this.localStorageService.set('ui-lang', value);
  }

  showMenu(event: MouseEvent) {
    this.isShowMenu = !this.isShowMenu;
    this.isShowSearch = false;
    this.cashbackStores = this.originalCashbackStores;
  }

  showSearch(event: MouseEvent) {
    this.isShowSearch = !this.isShowSearch;
  }

  onSearchChanged(searchText: string) {
    this.searchValue = searchText;
    this.filterStoresBySearch(searchText);
  }

  filterStoresBySearch(address: string) {
    if (!address) {
      return;
    }

    address = address.toLocaleLowerCase();

    this.cashbackStores = this.originalCashbackStores.filter((it) => {
      return this.translate
        .instant('layout.' + it.label)
        .toLocaleLowerCase()
        .includes(address);
    });
  }
}
