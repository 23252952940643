<footer class="footer">
  <div class="col-12">
    <div class="row">
      <div class="col-md-6">
        <h3>Контакты</h3>
        <ul>
          <li>
            <p>
              <img alt="" src="/assets/images/github.png" />
              <a href="https://github.com/Vunnupyx" target="_blank">Vunnupyx</a>
            </p>
          </li>
          <li>
            <p>
              <img alt="" src="/assets/images/telegram.png" />
              &#64;my_rifat
            </p>
          </li>
          <li>
            <p class="footer__link">
              <img alt="" src="/assets/images/email.png" /><a
                href="mailto:muhutdinovrifat@yandex.ru"
                >bptitsev&#64;yandex.ru</a
              >
            </p>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <h3>Сбор средств на Шоколадку</h3>
        <ul>
          <li>
            <p>
              <img alt="" src="/assets/images/donate.png" />
              <a href="https://pay.cloudtips.ru/p/29eaeceb" target="_blank"
                >Пожертвовать ₽</a
              >
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
