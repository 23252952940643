export const megamarket = {
  title: 'megamarket',
  subtitle: 'Обновлено: 15 ноября 2024 г.',
  img: 'assets/images/megamarket.png',
  coupons: [
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
  ],
};
export const edayandex = {
  title: 'edayandex',
  subtitle: 'Обновлено: 25 ноября 2024 г.',
  img: 'assets/images/ya-eda.png',
  coupons: [
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ в магазинах от 800₽ (max 500₽)',
      code: 'SHOP50',
    },
    {
      discount: '350₽',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 350₽ от 800₽ на первый заказ в разделе рестораны',
      code: 'HN6PAL8S',
    },
    {
      discount: '20%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 20% от 800 ₽ на первый заказ в разделе рестораны (max 1000₽)',
      code: 'HWVWP7NE',
    },
    {
      discount: '500₽',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 500₽ от 800₽ на первый заказ в Rostic’s',
      code: 'CHICK500',
    },
  ],
};
export const kuper = {
  title: 'kuper',
  subtitle: 'Обновлено: 15 ноября 2024 г.',
  img: 'assets/images/kuper.png',
  coupons: [
    {
      discount: '1000₽',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 1000₽ от 3000₽, если 2 месяца не было заказов (Магазины)',
      code: 'СНОВА',
    },
    {
      discount: '1000₽',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 1000₽ на первый заказ от 3000 рублей',
      code: 'bars1000',
    },
    {
      discount: '550₽',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 550₽ на второй заказ от 1490₽',
      code: 'ДВА',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
  ],
};
export const lenta = {
  title: 'lenta',
  subtitle: 'Обновлено: 15 ноября 2024 г.',
  img: 'assets/images/lenta.png',
  coupons: [
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
  ],
};
export const delivery = {
  title: 'delivery',
  subtitle: 'Обновлено: 15 ноября 2024 г.',
  img: 'assets/images/delivery.png',
  coupons: [
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
    {
      discount: '50%',
      title: 'ПРОМОКОД',
      subtitle: 'Скидка 50% на первый заказ от 800 рублей',
      code: 'SHOP50',
    },
  ],
};
export default { megamarket, edayandex, kuper, lenta, delivery };
