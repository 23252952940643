import { NgModule } from '@angular/core';
import { CouponsComponent } from './coupons.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { FooterModule } from 'app/shared/components/footer/footer.module';

const routes: Routes = [
  {
    path: 'megamarket',
    component: CouponsComponent,
    data: {
      breadcrumb: 'Megamarket',
    },
  },
  {
    path: 'edayandex',
    component: CouponsComponent,
    data: {
      breadcrumb: 'edayandex',
    },
  },
  {
    path: 'kuper',
    component: CouponsComponent,
    data: {
      breadcrumb: 'kuper',
    },
  },
  {
    path: 'lenta',
    component: CouponsComponent,
    data: {
      breadcrumb: 'lenta',
    },
  },
  {
    path: 'delivery',
    component: CouponsComponent,
    data: {
      breadcrumb: 'delivery',
    },
  },
];

const COMPONENTS = [CouponsComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    SharedModule,
    FooterModule,
    RouterModule.forChild(routes),
    CdkCopyToClipboard,
  ],
})
export class CouponsModule {}
