import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import STORE_COUPONS from 'app/services/constants/storeCoupons';
import { StoreCoupons } from 'app/models';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrl: './coupons.component.scss',
})
export class CouponsComponent implements OnInit {
  storeCoupons: StoreCoupons;

  subs = new Subscription();

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.storeCoupons = STORE_COUPONS[this.router.url.split('/').pop()];
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
